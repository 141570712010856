
import fingerprint from '~/mixins/fingerprint'
import login from '~/mixins/common/pages/login'
export default {
    name: 'Login',
    mixins: [fingerprint, login],
    middleware: 'auth',
    data: () => ({
        valid: false,
        form: {
            player_id: null,
            password: null,
            reg_fingerprint: null,
        },
        loginLoading: false,
    }),
    computed: {
        loading() {
            return this.$store.state.player.loading
        },
    },
    async mounted() {
        // Fingerprint js version 3.0
        this.form.reg_fingerprint = await this.visitorId()
    },
}
