import { render, staticRenderFns } from "./login.vue?vue&type=template&id=16976dde&scoped=true"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"
import style0 from "./login.vue?vue&type=style&index=0&id=16976dde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16976dde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4MobilePagesLoginPage: require('/app/components/Theme4/Mobile/Pages/LoginPage.vue').default,Theme4DesktopPagesLoginPage: require('/app/components/Theme4/Desktop/Pages/LoginPage.vue').default})
